import React from 'react'
import Navbar from './Navbar'
import SubNavbar from './SubNavbar'

const Header = (props) => {
  
	if(props.subNav) {
		return (
			<header>
				<SubNavbar />
			</header>
		)
	} else {
		return (
			<header>
				<Navbar />
			</header>
		)
	}
	
};

export default Header;
