import React, { useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { FaHamburger } from 'react-icons/fa'
import { Link } from "gatsby"
import logoTypeHome from "../images/svg/ArchebyteLogotype.svg"


const Navbar = (props) => {

  const show = useSelector(store => store.anim.show)
  // console.log("State of SHOW in NAVBAR: ",show); 

  // Render 2wice hack to ensure nav is rendered client-side
  const [hasMounted, setHasMounted] = useState(false);
  const [showMobileNav, setShowMobile] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);  



  if (!hasMounted) {
    return null;
  }  
  


  return (
    
    <div className={`navbox ${show ? "" : "hide-navbar"  }`}>
      
      <nav className="nav container" role="navigation" aria-label="main navigation">
        <div className="nav-group">

            <div className="logotype">
              <Link to="/" aria-label="navigate to home">
                <img src={logoTypeHome} alt=""  />
              </Link>
            </div>
            <div className={`hamburglar ${showMobileNav ? "open" : ""}`}>
              <button className="" aria-label="menu" aria-expanded={showMobileNav ? "true" : "false"}
                  onClick={()=>{setShowMobile(!showMobileNav)}} >
                <FaHamburger aria-hidden="true" />
              </button>
            </div>

        </div>

          <div className={`nav-links ${showMobileNav ? "show" : "" }`}>
            <ul>
              <li className="nav-about">
                <Link to="/about" activeClassName="active" >About</Link>
              </li>
              <li className="nav-dev">
                <Link to="/development" activeClassName="active" >Development</Link>
              </li>
              <li className="nav-blog">
                <Link to="/blog" activeClassName="active" >Blog</Link>
              </li>
            </ul>
        </div>
      </nav>
     
    </div>

  );
};

export default Navbar
