import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

const HelmetComponent = ({ title, description }) => {
  return (
    <StaticQuery
      query={graphql`
        query helmetQuery {
          site {
            siteMetadata {
              title
              author
              imageUrl
              description
              keywords
            }
          }
        }
      `}
      render={(data) => {
        //Take what we pass for each page, otherwise default to siteMetadata
        const metaTitle = title || data.site.siteMetadata.title
        const metaDesc = description || data.site.siteMetadata.description

        return (
          <>
          <a href="#main-content" className="sr-only-focusable skip">Skip to Main Content</a>
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=5, user-scalable=1"
            />
            <meta name="description" content={metaDesc} />
            <meta name="keywords" content={data.site.siteMetadata.keywords} />
            <title>{`${metaTitle} | Archebyte`}</title>
            <html lang="en" />
            {/* Google / Search Engine Meta Tags */}
            <meta itemprop="name" content={data.site.siteMetadata.author} />
            <meta
              itemprop="description"
              content={data.site.siteMetadata.description}
            />
            <meta itemprop="image" content={data.site.siteMetadata.imageUrl} />
          </Helmet>
          </>
        )
      }}
    />
  )
}
export default HelmetComponent
